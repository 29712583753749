/**
 * Duodecimo view component.
 * @module components/Views/DuodecimoView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import DataTable from 'react-data-table-component';

const mainColumns = [
  {
    name: 'Repasse',
    selector: (row) => row.title,
    format: (row, index) => <span className="title main">{row.title}</span>,
  },
  {
    name: 'Receita Prevista',
    selector: (row) => row.previsto,
    format: (row, index) => <span className="value main"></span>,
    right: true,
  },
  {
    name: 'Receita Total',
    selector: (row) => row.total,
    format: (row, index) => <span className="value main"></span>,
    right: true,
  },
];

const detalheColumns = [
  {
    name: 'Repasse',
    selector: (row) => row.data,
    format: (row, index) => <span className="title detail">{row.data}</span>,
  },
  {
    name: 'Receita Prevista',
    selector: (row) => row.previsto,
    format: (row, index) => (
      <span className="value detail">{row.previsto}</span>
    ),
    right: true,
  },
  {
    name: 'Receita Total',
    selector: (row) => row.valor,
    format: (row, index) => <span className="value detail">{row.valor}</span>,
    right: true,
  },
];

const DetalhesMensal = ({ data }) => {
  const conditionalRowStyles = [
    {
      when: (row) => row.is_total === true,
      style: {
        fontWeight: 'bold',
      },
    },
  ];
  return (
    <div>
      {data.repasses.length > 0 ? (
        <DataTable
          columns={detalheColumns}
          data={data.repasses}
          noTableHead
          conditionalRowStyles={conditionalRowStyles}
        />
      ) : (
        <span>Sem dados</span>
      )}
    </div>
  );
};

/**
 * Duodecimo view component class.
 * @function Duodecimo
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const DuodecimoView = (props) => {
  const { content } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const { title, description, tabela_repasses } = content;

  return (
    <Container id="page-document" className="view-wrapper duodecimo-view">
      <h1 className="documentFirstHeading">{title}</h1>
      {description && <p className="documentDescription">{description}</p>}
      <div className={'tabela'}>
        <DataTable
          columns={mainColumns}
          data={tabela_repasses}
          expandableRows
          expandableRowsComponent={DetalhesMensal}
          expandableRowExpanded={(row) => true}
          expandableRowDisabled={(row) => row.repasses.length === 0}
        />
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DuodecimoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    tabela_repasses: PropTypes.array,
  }).isRequired,
};

export default DuodecimoView;
